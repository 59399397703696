import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { words } from '../../data';

const Glossary = () => {
  const title = 'Glossary';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <table>
        <thead>
          <tr>
            <th>English</th>
            <th>中文</th>
          </tr>
        </thead>

        <tbody>
          {words.map((word, index) => (
            <tr key={index}>
              <td>{word.en}</td>
              <td>{word.zh}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default Glossary;
